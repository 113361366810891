import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
// import BockSert from './Serts/bock_sert.jpg'

const BockPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания Bock</h4>
                                <br/>
                                <p>
                                    «Bock GmbH» была основана в 1932 году Вильгельмом Боком и Гансом Гельднером в 
                                    Штутгарте.  Два основателя начинали с ремонта холодильников и установок систем охлаждения.
                                    В 1935 году Компания освоила производство открытых поршневых компрессоров.</p>
                                <p>
                                    На сегодняшний день компания «Bock» является высококлассным производителем компрессоров 
                                    и занимает одно из ведущих мест в ряду инновационных компаний в области промышленных 
                                    и коммерческих холодильных технологий.
                                </p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://bock.de/en" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="Bock website">
                                    &nbsp;&nbsp;www.bock.de
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2}>
                                {/* <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с Bock"
                                    src={BockSert}
                                    width="100%"
                                    />
                                </Zoom> */}
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BockPageHtml