import React from 'react';
import Seo from "../../components/seo"
import BockPageHtml from '../../components/OtherPages/PartnersPages/bockpage';

function BockPage() {
    return (
        <>
            <Seo 
                title="Партнер Рефинжиниринг Bock"
                description="Официальный партнер компании 'Рефинжиниринг'-Bock"
                keywords="Bock, холодильная, партнер"
            />
            <BockPageHtml />
        </>
    );
}

export default BockPage;